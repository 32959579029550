import { productsIndex } from 'helpers/constants/algolia'
import { NameResolverTypes } from '../types'

export const LOCAL_STORAGE_KEY = 'AUTOCOMPLETE_RECENT_SEARCHES'
export const LOCAL_STORAGE_KEY_TEST = '__AUTOCOMPLETE_RECENT_SEARCHES_PLUGIN_TEST_KEY__'
export const DEFAULT_SORTBY = 'newest'
export const DEFAULT_QUERY_SUGGESTIONS_POSTFIX = 'query_suggestions'
export const BRAND_NAME = 'DXL_PRODUCTS'

export const ELASTIC_PATH_HEADERS_DEFAULT = {
  'EP-Context-Tag': BRAND_NAME,
} as const

export const ELASTIC_PATH_HEADERS_LOGGED_IN = {
  'EP-Context-Tag': BRAND_NAME,
} as const

export const ALGOLIA_BASE_INDEX = process.env.NEXT_PUBLIC_ALGOLIA_BASE_INDEX || ''

export type AlgoliaIndexNameResolver = (params: {
  type?: NameResolverTypes
  locale: string
  sortBy?: string
  isLoggedIn?: boolean
}) => string

export const algoliaIndexNameResolver: AlgoliaIndexNameResolver = ({
  type,
  locale,
  sortBy = DEFAULT_SORTBY,
  isLoggedIn = false,
}: {
  type: NameResolverTypes
  locale: string
  sortBy?: string
  isLoggedIn?: boolean
}) => {
  switch (type) {
    case 'POPULAR':
      return `${productsIndex}_bestseller`
    default:
      return `${productsIndex}_${sortBy}`
  }
}

export type AlgoliaQuerySuggestionsIndexNameResolver = (params: {
  locale: string
  sortBy?: string
  isLoggedIn?: boolean
  querySuggestionsPostfix?: string
}) => string

export const algoliaQuerySuggestionsIndexNameResolver: AlgoliaQuerySuggestionsIndexNameResolver = ({
  locale,
  sortBy = DEFAULT_SORTBY,
  isLoggedIn = false,
  querySuggestionsPostfix = DEFAULT_QUERY_SUGGESTIONS_POSTFIX,
}: {
  locale: string
  sortBy?: string
  querySuggestionsPostfix?: string
  isLoggedIn?: boolean
}) => {
  return process.env.NEXT_PUBLIC_ALGOLIA_SUGGESTIONS_INDEX
}
